import { graphql, PageProps } from "gatsby";
import { first, isEmpty } from "lodash";
import React, { useContext, useEffect, useState } from "react";
import { Breadcrumb } from "../../components/breadcrumb";
import Footer from "../../components/footer";
import Layout from "../../components/layout";
import Navbar from "../../components/navbar";
import NotificationBar from "../../components/notification-bar";
import Seo from "../../components/seo";
import {
  TideItemForFaqDetailPageConnection,
  TideItemForHighlightEventsComponent,
  TideItemForPage,
  TideItemForSeizoenWedstrijd,
  TideItemForUspFolder,
  TideItemForWebsite,
  TideItemForWedstrijd,
} from "../../types";
import { getChildItemsWithTypename, parse } from "../../utils/component";
import MapPageComponents from "../../utils/mappers/map-page-components";
import { ComponentContext, ComponentContextType } from "../../contexts/component-provider";
import GlobalContext from "../../contexts/global-provider";
import { PackageProvider } from "../../contexts/package-provider";

export interface PageTemplateData {
  website: TideItemForWebsite;
  page: TideItemForPage;
  usps: TideItemForUspFolder;
  faqs: TideItemForFaqDetailPageConnection;
}
interface PageTemplateContext {
  language: string;
  breadcrumbs: Breadcrumb[];
}

declare global {
  interface Window {
    dataLayer: any;
  }
}

const PageTemplate: React.FC<PageProps<PageTemplateData, PageTemplateContext>> = ({ data, pageContext: { language, breadcrumbs } }) => {
  const globalContext = useContext(GlobalContext);
  const { catalogueIds, games, notificationBar, primaryNavigation, setGlobalData } = useContext<ComponentContextType>(ComponentContext);
  const [eventIds, setEventIds] = useState<string[]>([]);
  const [filteredGames, setFilteredGames] = useState<TideItemForWedstrijd[]>([]);

  useEffect(() => {
    (async () => {
      if (!games || games.length == 0) {
        await setGlobalData();
      }
    })();
  }, []);

  useEffect(() => {
    if (globalContext && globalContext.language !== language) {
      globalContext.setLanguage(language);
    }
  }, []);

  useEffect(() => {
    const highlightEventsComponents = getChildItemsWithTypename(
      "TideItemForHighlightEventsComponent",
      data.page
    ) as TideItemForHighlightEventsComponent[];
    if (first(highlightEventsComponents) && games && !isEmpty(catalogueIds)) {
      const tagId = first(highlightEventsComponents)?.content?.general?.tagId;
      if (tagId) {
        setFilteredGames(games.filter((g) => g.content?.general?.tagsIds?.includes(tagId)));
        setEventIds(
          games
            .filter((g) => g.content?.general?.tagsIds?.includes(tagId))
            .flatMap(
              (game) =>
                (getChildItemsWithTypename("TideItemForSeizoenWedstrijd", game) as TideItemForSeizoenWedstrijd[])
                  .filter((es) => es.content?.general?.eventStartDate && new Date(es.content.general.eventStartDate) >= new Date())
                  .map((es) => es.content?.general?.id) as string[]
            )
        );
      }
    }
  }, [games, catalogueIds]);

  return (
    <>
      {primaryNavigation && (
        <Layout>
          <Seo
            description={parse(data.page.content?.seo?.seoDescription)}
            keywords={parse(data.page.content?.seo?.seoKeywords)}
            title={parse(data.page.content?.seo?.seoTitle)}
            website={parse(data.website)}
            breadcrumbs={parse(breadcrumbs)}
          />
          {!isEmpty(notificationBar?.content?.general?.notification) && (
            <NotificationBar
              text={notificationBar?.content?.general?.notification ?? ""}
              icon={parse(notificationBar?.content?.general?.iconFontAwesome)}
            />
          )}
          <Navbar website={parse(data.website)} />

          <PackageProvider allGames={data.page.name === "Wedstrijden"} eventIds={eventIds}>
            <MapPageComponents
              website={data.website}
              page={data.page}
              usps={data.usps}
              faqs={data.faqs}
              breadcrumbs={breadcrumbs}
              games={filteredGames}
            />
          </PackageProvider>
          <Footer name={parse(data.website.name)} logo={parse(data.website.content?.general?.logo)} />
        </Layout>
      )}
    </>
  );
};

export default PageTemplate;

export const query = graphql`
  query PageQuery($pageId: String, $websiteId: String) {
    website: tideItemForWebsite(id: { eq: $websiteId }) {
      name
      content {
        general {
          phone
          helptext
          linkSgr
          linkThuiswinkelWaarborg
          linkTelephone
          logo {
            altText
            url
            title
          }
          linkLogo
        }
      }
    }
    page: tideItemForPage(id: { eq: $pageId }) {
      language
      name
      content {
        general {
          path
          title
        }
        seo {
          seoDescription
          seoKeywords
          seoTitle
        }
      }
      childItems {
        ...TideItemForHeroComponentFragment
        ...TideItemForIntroComponentFragment
        ...TideItemForTestimonialsFragment
        ...TideItemForUspComponentFragment
        ...TideItemForImageCardGridComponentFragment
        ...TideItemForTitleTextComponentFragment
        ...TideItemForSquareCardGridFragment
        ...PageOverviewComponentFragment
        ...TideItemForInfoBlocksComponentFragment
        ...TideItemForHighlightComponentFragment
        ...TideItemForHighlightEventsComponentFragment
        ...TideItemForFormContactComponentFragment
        ...TideItemForFormOfferComponentFragment
        ...TideItemForZohoFormFragment
      }
    }
    usps: tideItemForUspFolder(parentItem: { id: { eq: $websiteId } }) {
      name
      childItems {
        ... on TideItemForUspItem {
          __typename
          id
          name
          content {
            general {
              fai
              text
              title
            }
          }
        }
      }
    }
    faqs: allTideItemForFaqDetailPage(filter: { content: { general: { website: { elemMatch: { id: { eq: $websiteId } } } } } }) {
      nodes {
        __typename
        id
        itemId
        language
        templateName
        name
        content {
          general {
            title
            icon
            path
          }
        }
      }
    }
  }
`;
